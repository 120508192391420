<template>
  <div class="py-16">
    <div class="mb-10 lg:max-w-5xl md:mb-12">
      <h2
        class="mb-6 text-3xl font-bold leading-none tracking-tight  md:text-5xl text-purple"
      >
        Ecosystem Projects
      </h2>
      <h2
        class="mb-6 text-xl font-bold leading-none tracking-tight text-gray-900  md:text-3xl"
      >
        We participate in a variety of projects within the Solana Ecosystem
      </h2>
      <p class="text-base text-gray-700 md:text-lg">
        Whether it's through the provision of custom RPC servers for operators or hosted access to custom Solana based APIs, we work with a range of Solana ecosystem projects. 
      </p>
      <p class="text-base text-gray-700 md:text-lg">
          <router-link to="/contact"><span class="text-purple font-semibold">Contact us to learn more</span></router-link>
      </p>
    </div>
        <div className="max-w-7xl mx-auto">
          <div className="hidden md:grid grid-cols-1 gap-8 mt-12 items-center md:mt-12 lg:mt-12 md:gap-12 lg:gap-16 md:grid-cols-2"
          >
            <!-- wormhole -->
            <div className="text-left">
              <h2>Wormhole v2</h2>
              <p>
                We are one of the Wormhole v2 Guardians, supporting the ability to bridge tokens and other assets between BSC, Ethereum, Solana, Terra and Polygon. 
              </p><p>
                <a href="https://wormholebridge.com" class="text-purple font-semibold">Use the Wormhole v2 bridge now</a> or<br/> <a href="https://wormholenetwork.com/en/" class="text-purple font-semibold">learn more about the Wormhole network</a>
              </p>
            </div>
            <div>
            <a href="https://wormholebridge.com">
              <img
                src="./../assets/projects/wormhole.png"
                alt="Wormhole v2 Logo"
                className=""
              /></a>
            </div>
            <!-- /wormhole -->

            <div>
              <a href="https://pyth.network">
              <img
                src="./../assets/projects/pyth.svg"
                alt="Pyth.network logo"
                className=""
              />
              </a>
            </div>

            <div className="text-left">
              <h2>Pyth</h2>
              <p>
                  We support Pyth publishing through provision of reliable infrastructure for many of the Pyth publishers. We also provide hosted Pyth data feed access to traders. Access to historical Pyth pricing data coming soon.
              </p>
              <p>
                     <a href="https://pyth.network/" class="text-purple font-semibold">Learn more about Pyth</a>
              </p>
            </div>

            <div className="text-left">
              <h2>NeonEVM</h2>
              <p>
                  We run NeonEVM proxies to allow EVM based applications to run unmodified on the Solana chain. We also provide tailored RPC services for apps or other operators of NeonEVM proxies.
                  </p> 
              <p> 
                <a href="https://neon-labs.org/" class="text-purple font-semibold">Learn more about NeonEVM</a>
              </p>
            </div>
            <div>
              <a href="https://neon-labs.org/">
              <img
                src="./../assets/projects/neonevm.svg"
                alt="Neon EVM logo"
                className=""
              /></a>
            </div>

          </div>


          <div className="flex flex-col md:hidden mx-8 md:mx-16 lg:mx-28">
            <div className="mb-12">
              <div>
                <a href="https://wormholebridge.com"> 
                  <img
                    src="./../assets/projects/wormhole.png"
                    alt="Wormhole v2 Logo"
                    className=""
                  /></a>
              </div>
              <div className="text-left">
                <h2>Wormhole v2</h2>
                <p>
                  We are one of the Wormhole v2 Guardians, securing and supporting the ability to bridge tokens and other assets between BSC, Ethereum, Solana, Terra and Polygon. 
                </p>
                <p>
                  <a href="https://wormholebridge.com" class="text-purple font-semibold">Use the Wormhole v2 bridge now</a> or <a href="https://wormholenetwork.com/en/" class="text-purple font-semibold">learn more about the Wormhole network</a>
                </p>
              </div>
            </div>
            <div className="mb-12">
              <div>
                <a href="http://pyth.network">
                <img
                  src="./../assets/projects/pyth.svg"
                  alt="Pyth.network logo"
                  className=""
                />
                </a>
              </div>

              <div className="text-left">
                <h2>Pyth</h2>
                <p>
                  We support Pyth publishing through provision of reliable infrastructure for many of the Pyth publishers. We also provide hosted Pyth data feed access to traders. Access to historical Pyth pricing data coming soon.
                </p>
                <p>
                   <a href="https://pyth.network/" class="text-purple font-semibold">Learn more about Pyth</a>
                </p>
              </div>
            </div>
            <div className="mb-12">
              <div>
                <a href="https://neon-labs.org/">
                <img
                  src="./../assets/projects/neonevm.svg"
                  alt="Neon EVM logo"
                  className=""
                />
                </a>
              </div>
              <div className="text-left">
                <h2>NeonEVM</h2>
                <p>
                    We run NeonEVM proxies to allow EVM based applications to run unmodified on the Solana chain. We also provide managed services for apps or others who are intersted in operating their own NeonEVM proxies. 
                </p>
                <p>
                  <a href="https://neon-labs.org/" class="text-purple font-semibold">Learn more about NeonEVM</a>.
                </p>
              </div>
            </div>
          </div>
        </div>

  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import Button from "./../components/Button.vue";
import Footer from "./../components/Footer.vue";
import Header from "./../components/Header.vue";
export default {
  setup() {
    useHead({
      title: "Triton: Fast hosted Solana RPC access",
      meta: [
        {
          name: "description",
          content: "Community projects",
        },
      ],
    });
  },
  name: "Community Projects",
  components: {
    Button,
    Footer,
    Header,
  },
};
</script>
